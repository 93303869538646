<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="编辑礼品" :visible.sync="EditGiftDialogVisible" 
    :close-on-click-modal="false" width="30%" :close="CloseDialog">
        <el-form ref="GiftFormRef" :model="GiftForm" :rules="GiftFormRules" 
        label-width="80px" v-loading="Loading">
            <el-form-item label="名称：" prop="Name">
                <el-input  placeholder="名称" v-model="GiftForm.Name" @input="DataChangeTag = true"></el-input>
            </el-form-item>
            <el-form-item label="单价：" prop="Price">
                <el-input  placeholder="请输入单价" v-model="GiftForm.Price" @input="DataChangeTag = true"></el-input>
            </el-form-item>
            <el-form-item label="数量：" prop="Number">
                <el-input-number class="inputNumber_div" v-model="GiftForm.Number" :precision="0" :step="1" :min="0" :max="9999" @change="DataChangeTag = true"></el-input-number>
            </el-form-item>
             <el-form-item label="备注：" prop="Remark">
                <el-input  placeholder="请输入备注" v-model="GiftForm.Remark" @input="DataChangeTag = true"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog">取 消</el-button>
            <el-button type="primary" @click="EditGift" :disabled="Loading">提 交</el-button>
        </span>
    </el-dialog>    
</template> 

<script>
import { PutGift } from '../../../assets/js/api/giftapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        giftdata:Object,
    },
    computed:{
        EditGiftDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            GiftForm:{
                ID:0,
                Name:'',
                Price:0.0,
                Number:0,
                Remark:''
                },
            GiftFormRules:{
                Name: [
                    { required: true, message: '请输入礼品名称', trigger: 'blur' }
                ],
            },
            Loading:false,
            DataChangeTag:false,
        }
    },
    watch:{
        EditGiftDialogVisible:function (newVisible) {
            if(newVisible){
                this.GiftForm.ID = this.giftdata.id
                this.GiftForm.Name = this.giftdata.name
                this.GiftForm.Price = this.giftdata.price
                this.GiftForm.Number = this.giftdata.number
                this.GiftForm.Remark = this.giftdata.remark
                this.DataChangeTag = false
            }
        }
    },
    methods:{
        CloseDialog(){
            this.EditGiftDialogVisible = false
        },
        async EditGift(){
            if(this.DataChangeTag == false){
                this.$message.warning('数据没有修改，不需要提交');
                return
            }
            this.$refs.GiftFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }

            const confirmResult = await this.$confirm('确认操作是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.GiftForm.Price = Number(this.GiftForm.Price)
            this.Loading = true
            var ret = await PutGift(this.GiftForm)
            this.Loading = false
            if(ret.code == 1)
            {
                this.$message.success('修改礼品信息成功');
                this.$emit("ChangeEditGift", ret.data)
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.CloseDialog()
        }
    }
}
</script>
<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
    .inputNumber_div{
        width: 200px;
        display: flex;
    }
</style>