import axios from 'axios';

//查询礼品列表
export async function GetGiftList(queryinfo){
    var res = await axios.get('Gift/v1/GiftQuery', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//添加礼品信息
export async function PostGift(giftinfo){
    var res = await axios.post('Gift/v1/Gift', giftinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
}

//提交修改礼品信息
export async function PutGift(giftinfo){
    var res = await axios.put('Gift/v1/EditGift', giftinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
}

//根据ID删除礼品
export async function DeleteGift(id){
    var res = await axios.delete('Gift/v1/Gift',{ params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//获取所有可用礼品列表
export async function GetAllGiftList(){
    var res = await axios.get('Gift/v1/AllGift')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//根据PayID查询礼品列表
export async function GetGiftListByPayID(queryinfo){
  var res = await axios.get('Gift/v1/GiftByPay', {params: queryinfo})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

