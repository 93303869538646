<template>
    <div>
        <el-card>
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入礼品名称" v-model="QueryInfo.Name" clearable @clear="QueryInfoGiftList"> 
                        <el-button slot="append" icon="el-icon-search" @click="QueryInfoGiftList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="AddGiftDialogVisible = true">新建礼品</el-button>
                </el-col>
            </el-row>
            <el-table :data="GiftList" border stripe v-loading="Loading" @row-click="ShowEditDialog" height="calc(100vh - 40vh)">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="单价（￥）" prop="price"></el-table-column>
                <el-table-column label="剩余数量" prop="number"></el-table-column>
                <el-table-column label="使用数量" prop="amountUsed"></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="DeleteSelectGift(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="[1,2,5,10]"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="GiftTotal">
            </el-pagination>
        </el-card>
        <AddGiftDialog :visible.sync="AddGiftDialogVisible"
        v-on:ChangeAddGift="ParentChangeAddGift($event)">
        </AddGiftDialog>
        <EditGiftDialog :visible.sync="EditGiftDialogVisible" :giftdata="GiftData"
        v-on:ChangeEditGift='ParentChangeEditGift($event)'>
        </EditGiftDialog>
    </div>
</template>

<script>
import { GetGiftList, DeleteGift } from '../../../assets/js/api/giftapi'
import AddGiftDialog from '../Dialog/addgiftdialog.vue';
import EditGiftDialog from '../Dialog/editgiftdialog.vue';
export default {
    components:{
        AddGiftDialog,
        EditGiftDialog,
    },
    data(){
        return{
            QueryInfo:{
                Name:'',
                Start:1,
                Count:10,
            },
            GiftList:[],
            GiftTotal:0,
            Loading:false,
            AddGiftDialogVisible:false,
            EditGiftDialogVisible:false,
            GiftData:{},
        }
    },
    created(){
        this.QueryGiftList()
    },
    methods:{
        QueryInfoGiftList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = 10
            this.QueryGiftList()
        },
        async QueryGiftList(){
            this.Loading = true
            var ret = await GetGiftList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.GiftList = ret.data.gifts
                this.GiftTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryGiftList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryGiftList()
        },
        ParentChangeAddGift(gift){
            this.GiftList.unshift(gift)
            if(this.GiftList.length > this.QueryInfo.Count)
            {
                this.GiftList.pop()
            }
            this.GiftTotal += 1
        },
        ParentChangeEditGift(gift){
            this.GiftList.forEach(item=>{
                if(item.id == gift.id){
                    item.name = gift.name
                    item.price = gift.price
                    item.number = gift.number
                    item.remark = gift.remark
                }
            })
        },
        ShowEditDialog(gift){
            this.GiftData = gift
            this.EditGiftDialogVisible = true
        },
        async DeleteSelectGift(gift){
            const confirmResult = await this.$confirm('确认删除礼品 ' + gift.name +' ，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.Loading = true
            var ret = await DeleteGift(gift.id)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除礼品成功');
                this.QueryGiftList()
                return
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    
       
    }
}
</script>

<style lang="less" scoped>

</style>